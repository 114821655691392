.dashboard {
    padding: 2rem;
    background-color: #d6d2d2bd;
    height: 100vh;

    h1 {
        font-size: 3rem;
    }

    >h3 {
        font-size: 1.4rem;
        color: #474646ad;
    }

    .row {
        display: flex;
        flex-direction: row;
    }

    .col {
        display: flex;
        flex-direction: column;
    }

    svg {
        color: #005379;
    }

    &__content {
        padding: 4rem;
        display: flex;
        flex-direction: column;
        gap: 80px;

        .topStats {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 50%;
            margin: 0 auto;

            .stats-card {
                background-color: #FFF;
                padding: 20px;
                border-radius: 12px;
                box-shadow: 8px 8px 12px 2px rgba(0, 0, 0, 0.1);
                display: flex;
                flex-direction: row;
                gap: 40px;
                align-items: flex-end;

                div {
                    width: 50%;

                    p {
                        font-size: 2rem;
                        font-weight: 700;
                    }
                }

                .col {

                    h3 {
                        margin-top: 8px;
                        color: #383737bd;
                    }
                }
            }
        }
        
        .middleStats {

            &__content {
                gap: 40px;

                .dailyReports,
                .statsReport {
                    background-color: #FFF;
                    box-shadow: 8px 8px 12px 2px rgba(0, 0, 0, 0.1);
                    border-radius: 12px;
                    padding: 2rem;
                    width: calc(100% / 2);

                    .top.row {
                        justify-content: space-between;

                        .col {
                            h3 {
                                font-size: 1.6rem;
                            }
                            p {
                                font-size: 1.2rem;
                                color: #474646ad;
                            }
                        }
                    }

                    .statsReport {

                        &__content {
                            height: calc(100% - 80px);
                            margin-top: 2rem;

                            .row {
                                gap: 20px;
                                height: 100%;

                                >.col {
                                    width: calc(100% / 2);
                                    gap: 20px;

                                    .new-item {
                                        background-color: red;
                                        height: 100%;
                                        align-items: center;
                                        justify-content: center;
                                        border-radius: 12px;

                                        h3 {
                                            font-size: 1.6rem;
                                            font-weight: 700;
                                            text-align: center;
                                            color: #005379;
                                        }

                                        p {
                                            font-size: 1.2rem;
                                            color: #005379;
                                            text-align: center;
                                            margin-top: .4rem;
                                        }
                                    }

                                    &:nth-child(1) {
                                        .new-item:nth-child(1) {
                                            background-color: #ff4069a4;
                                        }
                                        .new-item:nth-child(2) {
                                            background-color: #75c8ffa4;
                                        }
                                    }
                                    &:nth-child(2) {
                                        .new-item:nth-child(1) {
                                            background-color: #ffe090a4; // Example color
                                        }
                                        .new-item:nth-child(2) {
                                            background-color: #A77BFFa4; // Example color
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
