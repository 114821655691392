.userDashboard {
    padding: 4rem;

    >h1 {
        font-size: 1.4rem;
        font-weight: 700;
        color: rgba(71, 70, 70, 0.6784313725);
    }

    &__content {
        padding: 2rem;

        .users {

            .errorMessages {
                text-align: center;
                background-color: red;
                width: 50%;
                margin: 20px auto;
                padding: 8px 0;
                color: #FFF;
                font-weight: 700;
                text-transform: uppercase;
            }


            table {
                width: 100%;
                border-collapse: separate;
                border-spacing: 0;
                border: 2px solid #8a8c8db7;
                border-radius: 12px;
                overflow: hidden;

                thead {
                    width: 100%;

                    
                    
                    tr {
                        width: 100%;
                        text-align: left;

                        th:first-child {
                            // border-top-left-radius: 9px; // Top left radius
                        }
                        th:last-child {
                            // border-top-right-radius: 9px; // Top right radius
                        }
                        th {
                            background-color: #8a8c8db7;
                        }

                        th {
                            padding: 10px 40px;
                        }
                    }
                }

                tbody {
                    padding: 0 40px;
                    tr {
                        height: 60px;

                        td:first-child {
                            border-bottom-left-radius: 12px; // Bottom left radius on the last row
                        }
                        td:last-child {
                            border-bottom-right-radius: 12px; // Bottom right radius on the last row
                        }
                        td {
                            padding: 0 40px;

                            select {
                                background-color: transparent;
                                cursor: pointer;
                            }

                            svg {
                                cursor: pointer;
                            }

                            &.role {
                                display: inline-block;
                            }

                        }
                        &:last-child td:first-child {
                            border-bottom-left-radius: 12px;
                        }
                        &:last-child td:last-child {
                            border-bottom-right-radius: 12px;
                        }
                    }
                }
            }
        }
    }
}


// Modal styling
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;

    .modal {
        background: white;
        padding: 20px;
        border-radius: 5px;
        width: 300px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

        .modal-content {
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            gap: 10px;

            input {
                border: 2px solid #005379;
                border-radius: 12px;
                padding: 4px 8px;
            }
        }
          
        .modal-actions {
            text-align: right;
            display: flex;
            flex-direction: row;
            gap: 2rem;
            width: 80%;
            margin: 0 auto;
        }

        .cancel,
        .confirm {
            padding: 8px 22px;
            border-radius: 12px;
            color: #FFF;
        }

        .cancel {
            background-color: red;
        }

        .confirm {
            background-color: #005379;
        }
    }
}
  