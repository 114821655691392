.footer {
    background-color: #005379;
    position: absolute;
    left: 0;
    right: 0;
    padding: 4rem;


    .container {
        width: 50%;
        margin: 0 auto;

        .logo {
            width: 240px;
            margin: 0 auto;

            img {
                max-height: 100px;
                margin: 0 auto;
            }
        }
        .contact {
            margin: 3rem auto 0;
            width: 50%;

            h2,
            p {
                text-align: center;
                color: #FFF;
            }

            h2 {
                font-size: 1.5rem;
                font-weight: bold;
            }
        }
    }

}